<script lang="ts">
  import demoGames from "./demo-games.json";
  import type { IDocument, IGame, ILesson } from "@zenstudy/lib/models";
  import { getCurrentLocale } from "@/lib/utils/i18n";
  import { defaultLocale } from "@zenstudy/lib/models";
  import PlayLessonQuiz from "@/components/app/course/lesson/PlayLessonQuiz.svelte";
  import {
    computeLessonResult,
    type LessonResult,
  } from "@/lib/logic/lesson-result";
  import EndScreen from "@/components/app/course/lesson/end-screen/EndScreen.svelte";
  import { getRelativeLocaleUrl } from "astro:i18n";

  let result: LessonResult | null = null; //= samplesLessonResult.quizLost;

  $: games = (demoGames[getCurrentLocale()] ??
    demoGames[defaultLocale]) as IGame[];
  $: lesson = {
    type: "quiz",
    num: 1,
    games: games.length,
    completed: false,
  } as ILesson;
  $: doc = {
    courseId: "",
    num: 1,
    isDeleted: false,
    // metadata
    name: "sample-file.pdf",
    type: "application/pdf",
    size: 1000000,
    // processing
    hasMore: true,
    nextPage: 1,
    builtLesson: 1,
    facts: 10,
    games: 10,
    // game
    nextLesson: 1,
  } as IDocument;

  async function done(answers: boolean[], score: number) {
    console.debug("done", { answers });

    // compute and show result
    let res = computeLessonResult(lesson, answers, score);
    result = res;
  }
</script>

<div class="h-[680px] overflow-clip rounded-2xl shadow-2xl">
  <div class="relative size-full">
    {#if !result}
      <PlayLessonQuiz
        {doc}
        {lesson}
        {games}
        {done}
        hideClose
        hideDocViewer
        hideFlag
      />
    {:else}
      <EndScreen
        {result}
        restart={() => (result = null)}
        next={() => {
          // go to app when won
          window.open(
            getRelativeLocaleUrl(getCurrentLocale(), "/app"),
            "_self",
          );
          result = null;
        }}
      />
    {/if}
  </div>
</div>
